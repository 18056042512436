import serviceAxios from "../request.js";

// 获取登录二维码

export const getLoginCode = (params) => {
  return serviceAxios({
    url: "/getLoginCode",
    method: "get",
    params,
  });
};

// 检查是否授权
export const checkLoginCode = (params) => {
  return serviceAxios({
    url: "/checkLoginCode",
    method: "get",
    params,
  });
};

// 获取用户昵称
export const getMyInfo = (data) => {
  return serviceAxios({
    url: "/mine/getMyInfo",
    method: "post",
    data,
  });
};

// 退出登录
export const LogOut = (params) => {
  return serviceAxios({
    url: "/loginOut",
    method: "get",
    params,
  });
};


